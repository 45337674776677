@charset "UTF-8";

@import "functions";

// Colors
$white: #ffffff;
//$light-grey: #EFEDED;
$light-grey: #ECECEC;
$dark-grey: rgb(119, 119, 119);
//$black: #262626;
$black: #000000;
$main-color: $dark-grey;

$max-width: 1280px;
$inner-width: 1024px;
$header-width: 1152px;
$border-width: 1.5px;
$border-color: $black;

$content-margin-top: rem-calc(64);
$col-right-margin-top: rem-calc(45);
$default-margin-bottom: rem-calc(45);

// Font
$title-font-size: 45;
$small-title-font-size: 40;
$big-text-font-size: 30;
$lead-text-font-size: 28;
$text-font-size: 20.8;
$small-font-size: 16;
$footer-text-font-size: 13.6;
$title-letter-spacing: rem-calc(-0.32);
$base-letter-spacing: rem-calc(0.16);

$nav-font-size: $big-text-font-size;
$arrow-default-width: rem-calc(24);
$arrow-right-default-width: rem-calc(6);

// Media Query Ranges
$small-breakpoint:  em-calc(640)  !default;
$medium-breakpoint: em-calc(768) !default;
$large-breakpoint:  em-calc(1024) !default;
$xlarge-breakpoint: em-calc(1440) !default;

$small-range:   (0, $small-breakpoint) !default;
$medium-range:  ($small-breakpoint  + em-calc(1), $medium-breakpoint) !default;
$large-range:   ($medium-breakpoint + em-calc(1), $large-breakpoint)  !default;
$xlarge-range:  ($large-breakpoint  + em-calc(1), $xlarge-breakpoint) !default;
$xxlarge-range: ($xlarge-breakpoint + em-calc(1), em-calc(99999999)) !default;

$screen: "only screen" !default;

$landscape: "#{$screen} and (orientation: landscape)" !default;
$portrait: "#{$screen} and (orientation: portrait)" !default;

$small-up: $screen !default;
$small-only: "#{$screen} and (max-width: #{upper-bound($small-range)})" !default;

$medium-up: "#{$screen} and (min-width:#{lower-bound($medium-range)})" !default;
$medium-only: "#{$screen} and (min-width:#{lower-bound($medium-range)}) and (max-width:#{upper-bound($medium-range)})" !default;
$medium-down: "#{$screen} and (max-width:#{upper-bound($medium-range)})" !default;

$large-up: "#{$screen} and (min-width:#{lower-bound($large-range)})" !default;
$large-only: "#{$screen} and (min-width:#{lower-bound($large-range)}) and (max-width:#{upper-bound($large-range)})" !default;
$large-down: "#{$screen} and (max-width:#{upper-bound($large-range)})" !default;

$xlarge-up: "#{$screen} and (min-width:#{lower-bound($xlarge-range)})" !default;
$xlarge-only: "#{$screen} and (min-width:#{lower-bound($xlarge-range)}) and (max-width:#{upper-bound($xlarge-range)})" !default;

$xxlarge-up: "#{$screen} and (min-width:#{lower-bound($xxlarge-range)})" !default;
$xxlarge-only: "#{$screen} and (min-width:#{lower-bound($xxlarge-range)}) and (max-width:#{upper-bound($xxlarge-range)})" !default;
