.sidebar{
  h2:first-of-type{
    margin-top: 2px;
  }
  &, p, a, li, ol {
    font-family: Book;
    letter-spacing: $base-letter-spacing;
    @include fontSize($small-font-size);
    line-height: 1.2em;
  }
  h2, h3, .sidebar-title{
    &, a{
      font-family: Demi;
      letter-spacing: $base-letter-spacing;
      @include fontSize(19.2);
      line-height: 1.17em;
      margin-bottom: rem-calc(12);
      text-underline-offset: 4px;
    }
  }
  h4{
    @include fontSize(17.6);
    line-height: 1.17em;
    margin-top: rem-calc(8);
    margin-bottom: rem-calc(8);
  }
  .open-now-content{
    span{
      display: block;
    }
  }
  .small-text{
    font-size: 0.8em;
    text-transform: uppercase;
  }
  .textAndImage{
    img{
      max-width: 100%;
    }
  }
}
.download-inline-block{
  a{
    display: inline-block;
    position: relative;
  }
}
.downloads{
  margin-left: 0 !important;
  padding-bottom: rem-calc(12);
  li{
    list-style: none;
  }
  .download{
    margin-bottom: rem-calc(8);
    position: relative;

    .download-link{
      word-wrap: break-word;
      margin: 0;
      padding-right: rem-calc(16);
      line-height: 1.1em;
    }
    .download-arrow{
      position: absolute;
      right: 0;
      top: 50%;
      transform: translateY(-50%);
    }
  }
}