div.tag-select {
  height: rem-calc(70);
  @media #{$medium-up} {
    position: relative;
    //top: rem-calc(-35);
    left: 50%;
    transform: translateX(-50%);
  }
  z-index: 1;
  @media #{$small-only} {
    position: relative;
  }

  & .title {
    position: relative;
    background-color: $white;
    padding-left: rem-calc(25);
    padding-right: rem-calc(60);
    height: rem-calc(70);
    width: rem-calc(213);
    max-width: rem-calc(213);
    max-height: rem-calc(70);
    line-height: rem-calc(70);
    margin: 0;
    cursor: pointer;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    border: 1px solid $black;
    margin-left: auto;margin-right: auto;
    & span {
      vertical-align: middle;
      //@include font-family(blogText, 27, 27 * 1.6, $black);
    }
    & span.triangle{
      position: absolute;
      display: inline-block;
      margin: 0;
      height: rem-calc(70);
      line-height: rem-calc(70);
      width: rem-calc(22);
      right: rem-calc(30);
      & svg {
        vertical-align: middle;
        transform: rotateZ(0deg);
        transition: transform 200ms;
      }
    }
  }

  &.deployed {


    & .title span.triangle svg{
      transform: rotateZ(180deg);
    }

    & ul.options {
      overflow: auto;
      min-height: rem-calc(300);
      max-height: rem-calc(750);

      @media #{$small-only} {
        max-height: calc(100vh - 70px);
      }
      & li {
        transform: translateY(0px)
      }
    }
  }


  & ul.options {
    margin-top: -1px;
    max-height: 0;
    min-height: 0;
    overflow: hidden;
    transition: max-height 500ms;
    & li {
      transform: translateY(-800px);
      transition: transform 400ms;

    }
    @media #{$small-only} {
      max-width: rem-calc(400);
      margin: 0 auto;
    }
  }

  & li {
    list-style-type: none;
    background-color: $white;
    border-right: 1px solid $black;
    border-bottom:1px solid $black;
    border-left:1px solid $black;
    &:first-child{
      border-top: 1px solid $black;
    }
    margin:0;

    &.option {
      &:hover {
        background-color: $blogGrey;
      }
      & .active {
        //background-color: $blogBlue;
      }
      @media #{$medium-up} {
        width: rem-calc(400);
      }

      @media #{$small-only} {
        max-width: rem-calc(400);
        margin: 0 auto;
      }
    }
    & a, & span {
      vertical-align: middle;
      //@include font-family(blogText, 27, 27 * 1.6, $black);
      cursor: pointer;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      max-width: 100%;
      display: inline-block;
      margin: 0;
    }

    & a, &.title {
      display: block;
      height: rem-calc(70);
      max-width: 100%;
      max-height: rem-calc(70);
      line-height: rem-calc(70);
      padding-left: rem-calc(25);
      padding-right: rem-calc(25);
      margin: 0;
      cursor: pointer;

    }

  }

}

// Blog Liste
//.blog-list{
//
//}
//.blog-entry{
//  position: relative;
//  .date{
//    position: absolute;
//    color: $black;
//    background-color: $white;
//    top: 0;
//    left: 50%;
//    transform: translateY(-50%) translateX(-50%);
//    padding: .66667rem 1.33333rem;
//    border: 1px solid $black;
//    letter-spacing: $base-letter-spacing;
//    @include fontSize($small-font-size);
//    line-height: 1.17em;
//  }
//  .author{
//    border-top: 1px solid $black;
//    text-align: right;
//  }
//  .image-wrapper{
//    display: block;
//    height: rem-calc(390);
//    background-position: center;
//    background-repeat: no-repeat;
//    background-size: cover;
//  }
//}

.blog-list, .lastBlogPost {
  width: 100%;
  max-width: rem-calc(850);
  margin-left: auto;
  margin-right: auto;


  &.hasTagFilter {
    padding-top: rem-calc(50);
    @media #{$small-only} {
      padding-top: rem-calc(40);
    }
  }
  & .blog-entry {
    position: relative;
    margin-bottom: rem-calc(80);
    & .date {
      position: absolute;
      //@include font-family(blogText, ($blogTextSize - 2), ($blogTextSize - 2)* 1.6, $black);
      top: rem-calc(0);
      left: 50%;
      transform: translateY(-50%) translateX(-50%);
      padding: rem-calc(10) rem-calc(20);
      &:before {
        content: "";
        position: absolute;
        top: rem-calc(0); left: 0; right: 0; bottom: 0;
        background-color: $white;
        border: 1px solid $black;
        z-index: -1;
      }
    }
    & .image-wrapper {
      display: block;
      height: rem-calc(400);
      background-position: center;
      background-repeat: no-repeat;
      background-size: cover;

      @media #{$small-only} {
        position: absolute;
        top: rem-calc(85);
      }

      @media #{$medium-up} {
        width: 38%;
      }

    }
    & .text-wrapper {
      display: block;
      position: relative;
      padding-left: rem-calc(35);
      padding-right: 0;
      padding-top: rem-calc(55);

      @media #{$medium-up} {
        width: 62%;
      }

      & h2.entryTitle {
        position: relative;
        margin-bottom: rem-calc(20);
        &:before {
          content: "";
          position: absolute;
          top: rem-calc(-5);
          left: rem-calc(-13);
          width: rem-calc(130);
          height: calc(100% + 15px);
          background-color: $white;
          z-index: -1;
        }
        & span {
          white-space: pre-wrap;
          margin-bottom: 0;
        }

        @media #{$small-only} {
          max-width: 80%;
        }
      }

      & div.text {
        & span.arrow {
          padding-left: rem-calc(11);
          width: rem-calc(30);
          height: rem-calc(17);
          line-height: rem-calc(17);
          & svg {
            vertical-align: middle;
            width: $arrow-default-width;
            height: rem-calc(12);
          }
        }
      }

      @media #{$small-only}{
        padding-top: 0;
        padding-left: 0;

        & h2.entryTitle {
          display: block;
          padding-top: rem-calc(20);
          height: rem-calc(393);
          &:before {
            display: none;
          }

          & span {
            display: inline-block;
            padding: rem-calc(5) rem-calc(5) rem-calc(15) rem-calc(10);
          }
        }
      }

    }

    &.text-left {
      .row-layout{
        flex-direction: row-reverse;
        -webkit-flex-direction: row-reverse;
        -ms-flex-direction: row-reverse;
      }
      & .text-wrapper {
        @media #{$medium-up} {
          text-align: right;
          padding-left: 0;
          padding-right: rem-calc(35);
        }
        & h2.entryTitle {
          @media #{$medium-up} {
            transform: translateX(0);
          }
          &:before {
            @media #{$medium-up} {
              left: auto;
              right: 0;
            }
          }
        }

      }
    }
    & .tags-wrapper {
      text-align: right;
      width: 100%;
      margin: rem-calc(32) 0;
      & ul.tags {
        @include flexBoxes(flex-end, center, row, wrap);
        & li {
          list-style: none;
          font-family: Book;
          text-transform: uppercase;
          letter-spacing: $base-letter-spacing;
          @include fontSize($small-font-size);
          line-height: 1.17em;
          &:not(:last-child){
            padding-right: rem-calc(15);
          }
        }
        & li.crunch {
          @include fontSize(25);
        }
      }
      @media #{$small-only} {
        background-color: $blogGrey;
        padding: rem-calc(20);
        & li.author-tag {
          display: block;
          width: 100%;
        }
      }

      & li.author-tag {
        & .author {
          border: none;
        }

      }
    }

    @media #{$small-only} {
      display: block;
      max-width: 100%;
      margin-right: 0;
      margin-left: 0;


    }
  }

}

.lastBlogPost {
  max-width: 100%;
}