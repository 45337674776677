html, body, div, ul {
    margin: 0;
    padding: 0;
}

body {
  font-family: Book, Arial, sans-serif;
  font-weight: normal;
  letter-spacing: $base-letter-spacing;
  @include fontSize($text-font-size);
  line-height: 1.35em;
  background-color: #fff;
  color: #333;
  text-align: left;
  opacity: 0;
  transition: opacity .5s;
  &.loaded{
    opacity: 1;
  }
  &.fancybox-active, &.nav-open{
    overflow: hidden;
    height: 100vh;
  }

  //hide Scrollbars
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
  &::-webkit-scrollbar {
    display: none;
  }
}

div, main, aside, section {
  border: 0;
  box-sizing: border-box;
}

a {
  color: $main-color;
  text-decoration: none;
  cursor: pointer;
}

a, .as-link{
  cursor: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='34px' height='44px' viewBox='0 0 67.4 86'%3E%3Cg id='Layer_2' data-name='Layer 2'%3E%3Cg id='Ebene_1' data-name='Ebene 1'%3E%3Cpath fill='%23fff' stroke='%23231f20' stroke-miterlimit='10' stroke-width='1.52px' d='M54.8,39.6a5.9,5.9,0,0,1,5.9-5.9,5.8,5.8,0,0,1,5.9,5.9V60.5a24.7,24.7,0,0,1-45.1,14L2,47.9a6.8,6.8,0,0,1,1.5-9.4A6.8,6.8,0,0,1,12.9,40l5.7,8V7.1a6.4,6.4,0,0,1,12.7,0V28a5.9,5.9,0,0,1,5.9-5.9A6,6,0,0,1,43.1,28v5.7a5.9,5.9,0,1,1,11.7,0v5.9Z'/%3E%3C/g%3E%3C/g%3E%3C/svg%3E") 12 0, pointer;
}

input,
textarea,
select,
img,
a {
  display: block;
  &:focus{
    outline: #fffefd solid 2px;
    //outline: none;
  }
}

img {
    border: none;
}

.clearfix {
    height: 1px;
    font-size: 1px;
    clear: both;
}

br {
    letter-spacing: normal;
}

table, tr, td {
    border-collapse: collapse;
}

hr {
    clear: both;
    display: block;
    height: 1px;
    margin-bottom: 10px;
    margin-top: 10px;
    margin-left: 0;
    text-align: left;
    color: #fff;
    border: 0;
    border-top: 1px solid #000;
    height: 1px;
}

.spacerAfter1 {
    clear: both;
    height: 10px !important;
}

.spacerAfter2 {
    clear: both;
    height: 20px !important;
}

.spacerBefore1 {
    margin-top: 15px !important;
}

.spacerBefore2 {
    margin-top: 30px !important;
}

.clear, .clearer {
    clear: both;
    overflow: hidden;
    display: block;
    margin: 0;
    padding: 0;
    border: none;
    height: 1px;
    line-height: 0;
}

h1, h2, h3, h4, h5, h6 {
  padding: 0;
  margin: 0;
  font-weight: normal;
}

h1{
  font-family: Medium, Arial, sans-serif;
  letter-spacing: 0.03rem;
  @include fontSize($title-font-size);
  line-height: 1em;
  font-weight: 500;
  margin-top: rem-calc(64);
  @media #{$small-only}{
    margin-top: rem-calc(32);
  }
  &.big-title{
    font-family: AfrikaSemiBold;
    @include fontSize(100);
    line-height:0.9em;
    @media #{$medium-down} {
      font-size: rem-calc(52);
    }
  }
}

h2{
  font-family: Book, Arial, sans-serif;
  letter-spacing: $title-letter-spacing;
  @include fontSize($title-font-size);
  font-weight: normal;
  line-height: 1em;
  margin-top: rem-calc(32);
  margin-bottom: rem-calc(32);
  @media #{$small-only} {
    margin-top: rem-calc(16);
    margin-bottom: rem-calc(16);
  }
  &.big-title{
    font-family: AfrikaSemiBold;
    @include fontSize(43.2);
    margin-top: -2px;
    margin-bottom: rem-calc(24);
    line-height:0.9em;
    @media #{$medium-down} {
      font-size: rem-calc(23);
    }
  }
}
h3, .h3-style{
  font-family: Demi, Arial, sans-serif;
  letter-spacing: $base-letter-spacing;
  @include fontSize($big-text-font-size);
  line-height: 1em;
  margin-top: rem-calc(24);
  margin-bottom: rem-calc(24);
}
h4, .h4-style{
  font-family: Book, Arial, sans-serif;
  letter-spacing: $base-letter-spacing;
  @include fontSize($big-text-font-size);
  line-height: 1em;
  margin-top: rem-calc(24);
  margin-bottom: rem-calc(24);
}
h5, .h5-style{
  font-family: Demi, Arial, sans-serif;
  letter-spacing: rem-calc(0.32);
  @include fontSize($text-font-size);
  line-height: 1.35em;
  margin-top: rem-calc(12);
  margin-bottom: rem-calc(12);
  @media #{$medium-down} {
    font-size: rem-calc(18);
    font-family: Book, Arial, sans-serif;
  }
}
h6, .h6-style{
  font-family: Book, Arial, sans-serif;
  text-transform: uppercase;
  letter-spacing: $base-letter-spacing;
  @include fontSize($small-font-size);
  line-height: 1.17em;
  margin-top: rem-calc(6);
  margin-bottom: rem-calc(6);
}
.lead{
  &, p{
    font-family: Medium, Arial, sans-serif;
    color: #888;
    letter-spacing: $base-letter-spacing;
    @include fontSize($lead-text-font-size);
    line-height: 1.15em;
    @media #{$medium-down} {
      font-size: 20px;
      line-height: 1.3em;
    }
  }
}
footer{
  p, a, div{
    font-family: Book, Arial, sans-serif;
    @include fontSize($footer-text-font-size);
    line-height: 1.3em;
  }
}
.contententry, .content-entry{
  ul{
    margin-left: 6%;
  }
}
p, li{
  font-family: Book, Arial, sans-serif;
  letter-spacing: $base-letter-spacing;
  line-height: 1.35em;
  @include fontSize($text-font-size);
  strong{
    font-family: Demi, Arial, sans-serif;
    font-weight: normal;
  }

  margin-top: 12px;
  margin-bottom: 12px;
  @media #{$medium-down} {
    font-size: rem-calc(16);
  }
}
a{
  color: $black;
}
.button{
  border: none;
  background-color: #000000;
  color: #ffffff;
  font-family: Book, Arial, sans-serif;
  text-transform: uppercase;
  letter-spacing: 0.01rem;
  font-size: 1rem;
  line-height: 1.17em;
  padding-top: 6px;
  padding-bottom: 5px;
}
.caption{
  &, p, a, li {
    font-family: Book, Arial, sans-serif;
    letter-spacing: $base-letter-spacing;
    @include fontSize($small-font-size);
    line-height: 1.2em;
  }
}
ul, ol{
  font-family: Book, Arial, sans-serif;
  letter-spacing: $base-letter-spacing;
  @include fontSize(18);
  line-height: 1.1em;
}
ol{
  padding-inline-start: 3.4em;
}

.contententry, .content-entry, .textAndImage, .texts, .fulltext, .contact-infos, .blogTextImage {
  p, li, ol {
    text-underline-offset: 2px;
    a {
      text-decoration: underline;
      display: inline-block;
    }
  }
}
.container{
  .text{
    a {
      text-decoration: underline;
      display: inline-block;
    }
  }
}

hr {
    border-color: $dark-grey;
}

.arrow-right{
  display: inline-block;
  padding-left: 5px;
  margin-bottom: -2px;
  padding-right: 5px;
  svg{
    display: block;
    width: $arrow-right-default-width;
  }
}

.contententry{
  margin-bottom: $default-margin-bottom;
  @media #{$small-only} {
    margin-bottom: $default-margin-bottom*.6;
  }
}

figure{
  margin: 0;
}

.embed-caption{
  margin-top: rem-calc(10);
}


.text-align-center{
  text-align: center;
}
.text-align-right{
  text-align: right;
}
.no-margin-top{
  margin-top: 0;
}
.no-margin-bottom{
  margin-bottom: 0;
}

.skip-link{
  width: 1px;
  height: 1px;
  overflow: hidden;
  display: block;
  position: absolute;
  &:focus{
    position: fixed;
    z-index: 1;
    width: auto;
    height: auto;
  }
}

.hide{
  display: none;
}
.show-small-only{
  display: none;
  @media #{$small-only} {
    display: block;
  }
}
.show-for-medium-down{
  @media #{$large-up} {
    display: none;
  }
}
.show-for-large-up{
  @media #{$large-down} {
    display: none;
  }
}
.show-for-medium-up{
  @media #{$medium-down} {
    display: none;
  }
}
@media #{$small-only} {
  .mgnlLikeBtn{
    display: none;
  }
}

#main_wrapper{
  max-width: $max-width;
  margin: auto;
  main:not(#colleft){
    width: 100%;
  }
}
#full_width_area{
  width: 100%;
}

img{
  max-width: 100%;
}

#colleft{
  width: 80%;
  position: relative;
  padding-right: rem-calc(12);
  .textAndImage:first-of-type, .exhibition-text{
    .lead{
      @media #{$medium-up} {
        float: left;
        width: 56%;
        padding-right: rem-calc(12);
      }
      @media #{$small-only} {
        position: relative;
        &:after{
          content: '';
          position: absolute;
          height: 1px;
          bottom: rem-calc(-28);
          background-color: $black;
          width: 42%;
          left: 0;
        }
      }
    }
    .fulltext{
      p:first-child{
        margin-top: rem-calc(177);
        @media #{$medium-down} {
          margin-top: rem-calc(52);
        }
      }
      &.without-lead{
        p:first-child {
          margin-top: 0;
          padding-top: rem-calc(32);
          @media #{$medium-down} {
            padding-top: rem-calc(24);
          }
        }
      }
    }
  }
  @media #{$medium-down} {
    width: 100%;
    padding-right: rem-calc(0);
  }
}
#colright{
  width: 20%;
  //margin-top: calc(45px + 22.5px);
  margin-top: rem-calc(45);
  padding-left: rem-calc(12);
  padding-top: rem-calc(4);
  &:not(.exhibition-infos){
    .content{
      .contententry:first-child{
        h3:first-of-type{
          margin-top: 0;
        }
      }
    }
  }
  @media #{$medium-down} {
    width: 70%;
    margin-top: 0 !important;
    padding: rem-calc(24) rem-calc(24) 0 0;
    border-top: 1px solid $black;
  }
}


header{
  background-color: $white;
}

header, #contentWrapper, footer{
  padding-left: 45px;
  padding-right: 45px;
  @media #{$medium-down} {
    padding-left: 12px;
    padding-right: 12px;
  }
}

.edit-link{
  position: fixed;
  top: 10px;
  right: 10px;
  background: lightskyblue;
  padding: rem-calc(10);
  @include fontSize($small-font-size);
  cursor: pointer;
}

select {
  width: 100%;
  padding: 5px 35px 5px 5px;
  @include fontSize($small-font-size);
  border: none;
  height: 34px;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background: none;
}
select::-ms-expand {
  display: none; /* Remove default arrow in Internet Explorer 10 and 11 */
}

.social-links{
  li{
    list-style: none;
    padding-left: rem-calc(12);
    img{
      height: rem-calc(24);
      @media #{$medium-down} {
        height: rem-calc(16);
      }
      display: block;
    }
  }
}

.custom-audio{
  .custom-audio-pause{
    //display: none;
  }
}

.overlay-loading {
  position: absolute;
  background-image: url('../images/oval.svg');
  height: 100px;
  width: 100%;
  background-position: center center;
  background-repeat: no-repeat;
  display: none;
}

// Collapsible Elements
.collapsible-container{
  .container {
    display: none;
  }
}
