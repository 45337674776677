@charset "UTF-8";

.blogTextImage {

  & .quote {
    @media #{$medium-up} {
      margin-left: rem-calc(30)
    }
    padding: rem-calc(25) rem-calc(35);
    margin-top: rem-calc(60);
    margin-bottom: rem-calc(60);
    border-left: 2px solid #808080;

    //@include font-family(blogTitle, $blogQuoteSize, $blogQuoteSize * 1.375, $black);

  }

  & h2 {
    //@include font-family(blogTextBold, $blogH2Size, $blogH2Size * 1.6, $black);
  }

  & .text {
    padding: 0; //override
    margin-bottom: rem-calc(30);
  }

  & .image {
    margin-bottom: rem-calc(45);
    & img {
      width: 100%;
      display: block;
    }

    & .caption {
      text-align: center; //override;
      & p {
        margin-bottom: 0;
      }
    }
  }
}


.blogTextImageRightCol {
  padding: rem-calc(6) 0;
  background-color: $blogGrey;
  text-align: center;
  margin-bottom: rem-calc(30);
  h3{

  }
  & h3, .text {
    text-align: center;
    padding: 0 rem-calc(6);
  }
  & .text {
    padding: 0;
  }

  & .image {
    margin-bottom: rem-calc(12);
    & img {
      width: 100%;
    }
    & .caption {
      text-align: left; //override;
      font-style: normal; //override;
      background-color: $blogGrey; //override
      & p {
        margin-top: 0;
        margin-bottom: 0;
      }
    }
  }

  & a.compo-link {
    display: inline-block;
    width: auto;
    text-align: center;
    & span{
      display: inline-block;
      width: auto;
      margin: 0;
      &.arrow{
        width: rem-calc(28);
        display: inline-block;
        vertical-align: middle;
        padding-left: rem-calc(6);
        svg{
          display: block;
        }
      }
    }
  }
}
