.embed-container {
  position: relative;
  padding-bottom: 56.25%;
  height: 0;
  overflow: hidden;
  max-width: 100%;
}

.embed-container iframe,
.embed-container object,
.embed-container embed {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.embed-wrapper{
  &.video-transcripts{
    &.show-transcripts{
      opacity: 0;
      animation: fadeIn .3s forwards;
      -webkit-animation: fadeIn .3s forwards;
      @media #{$large-up} {
        @include flexBoxes(flex-start, stretch, row, wrap);
        .embed-wrap{
          width: 65%;
        }
      }
      .embed-transcripts{
        position: relative;
        @media #{$large-up} {
          width: 30%;
          overflow: hidden;
        }
        .collapsible-wrapper{
          @media #{$large-up} {
            overflow: scroll;
            position: absolute;
            padding: 4px 8px;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
          }
          border: 1px solid $black;
          border-left: none;
          border-right: none;
          .collapsible-title{
            span.close{
              display: inline-block;
            }
          }
        }
      }
      .embed-caption{
        width: 100%;
      }
    }
    &:not(.show-transcripts){
      .embed-transcripts{
        width: 35%;
        .collapsible-content{
          max-height: 0;
          opacity: 0;
          overflow: hidden;
        }
      }
    }
    .embed-transcripts{
      p{
        font-size: rem-calc($small-font-size);
      }
    }

    .collapsible-title{
      position: relative;
      cursor: pointer;
      padding: 5px 20px 5px 0px;
      span.arrow-right{
        margin-bottom: -5px;
        svg{
          transition: all .3s;
          width: rem-calc(24);
          height: rem-calc(24);
        }
      }
      span.close{
        display: none;
        position: absolute;
        right: 0;
        svg{
          width: rem-calc(18);
          height: rem-calc(18);
        }
      }
    }
  }
}

@keyframes fadeIn {
  0% {opacity:0;}
  100% {opacity:1;}
}

@-webkit-keyframes fadeIn {
  0% {opacity:0;}
  100% {opacity:1;}
}