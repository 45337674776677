.screen-page {

  #contentWrapper {
    padding-left: 70px;
    padding-right: 70px;
  }

  #logo {
    width: 300px;
    margin-top: 60px;
  }

  .item-list-detail .show-for-medium-up {
    display: flex !important;
  }
  .item-list-detail .show-for-medium-down {
    display: none !important;
  }

  .hide,
  a.text-align-center,
  .list-filter {
    display: none;
  }

  .item-date-detail {
    .evt-day {
      font-size: 3rem;
    }
    .evt-time {
      font-size: 1.8rem;
    }
  }

  .item-txt-detail {
    .eventtype {
      //font-size: 20px;
      @include fontSize(19);
      margin-bottom: 0.1rem;
    }
    .h4-style {
      //font-size: 38px;
      font-weight: normal !important;
      @include fontSize(36);
    }
  }

  .item-detail-action {
    display: none;
  }

  #qrcode {
    margin-bottom: 44px;
    margin-left: auto;
    width: 90px;
    //width: 170px;
    //width: 256px;
  }

  .overlay {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    opacity: 0;
    transition: opacity .5s;

    &.show {
      opacity: 1;
    }
  }
}
