.form-wrapper{
  form{
    .field{
      input{
        display: none;
      }
    }
    fieldset{
      border: none;
      padding: 0;
      margin: 0;
    }

    input, textarea{
      padding: 0.375rem 0.5rem;
      width: 96%;
      border: none;
      font-family: Book;
      letter-spacing: $base-letter-spacing;
      @include fontSize($text-font-size);
      line-height: 1.35em;
      background-color: $light-grey;
      margin-bottom: rem-calc(24);
    }

    input[type="submit"]{
      width: auto;
      background-color: $black;
      color: $white;
    }
    input[type="submit"], label{
      text-transform: uppercase;
      @include fontSize($small-font-size);
      line-height: 1.17em;
    }
  }

  .check-box-label{
    cursor: pointer;
    text-align: center;
    display: inline-block;
    @media #{$medium-down} {
      text-align: left;
    }
    &.cbx{
      -webkit-user-select: none;
      user-select: none;
      cursor: pointer;
      padding: 6px 8px 6px 0;
      border-radius: 6px;
      overflow: hidden;
      transition: all .2s ease;
      margin-bottom: rem-calc(12);
      &:not(:last-child){
        margin-right: 6px;
      }
      span{
        float: left;
        vertical-align: middle;
        transform: translate3d(0,0,0);
        &:first-child{
          position: relative;
          width: 18px;
          height: 18px;
          top: -2px;
          margin-right: 8px;
          border-radius: 4px;
          transform: scale(1);
          border: 1px solid #000000;
          transition: all .2s ease;
          box-shadow: 0 1px 1px rgba(#00104B,.05);
          svg{
            position: absolute;
            top: 3px;
            left: 2px;
            fill: none;
            stroke: #000000;
            stroke-width: 2;
            stroke-linecap: round;
            stroke-linejoin: round;
            stroke-dasharray: 16px;
            stroke-dashoffset: 16px;
            transition: all .3s ease;
            transition-delay: .1s;
            transform: translate3d(0,0,0);
          }
        }
        //&:last-child {
        //  padding-left: 8px;
        //  line-height: 18px;
        //}
      }
      &:hover span:first-child{
        border-color: $main-color;
      }

    }
  }
  .inp-cbx{
    position: absolute;
    margin: 0;
    max-width: 100%;
    width: 20px;
    height: 26px;
    z-index: -1;
    opacity: 0;
  }

  .inp-cbx:checked + .cbx{

    span{
      &:first-child{
        //background: $blue;
        //border-color: $blue;
        animation: wave .4s ease;
        svg{
          stroke-dashoffset: 0;
        }
      }
    }
  }
  @keyframes wave {
    50% {
      transform: scale(.9);
    }
  }
}

.select-box {
  position: relative;
  display: block;
  width: 100%;
  margin: 0 auto;

  &__current {
    //position: relative;
    box-shadow: 0 15px 30px -10px transparentize(#000, 0.9);
    cursor: pointer;
    outline: none;

    &:focus {
      & + .select-box__list {
        opacity: 1;
        max-height: 1500px;
        // We have to set "animation-name: none;" to make the list visible (read below how it works)
        animation-name: none;
        .select-box__option {
          cursor: pointer;
        }
      }

      .select-box__icon {
        //transform: translateY(-50%) rotate(180deg);
      }
    }
  }

  &__value {
    display: flex;
  }

  &__input {
    display: none;

    &:checked + .select-box__input-text {
      display: block;
    }
  }

  &__input-text {
    display: none;
    margin: 0;
    box-sizing: border-box;
  }

  &__list {
    position: absolute;
    border: 1px solid $black;
    box-sizing: border-box;
    width: calc(100% + 2px);
    background-color: $white;
    left: -1px;
    top: 100%;
    z-index: 10;
    padding: 0;
    margin-left: 0 !important;
    list-style: none;
    max-height: 0;
    overflow: hidden;
    opacity: 0;
    transition: max-height .3s;

    box-shadow: 0 15px 30px -10px transparentize(#000, 0.9);

    .filter-item{
      padding-right: rem-calc(12);
    }
  }

  &__option {
    display: block;
    padding: 8px 15px;
    background-color: #fff;

    &:hover,
    &:focus {
      color: $black;
      background-color: $light-grey;
    }
  }
}

.form-row{
  .select-box{
    padding: 0.375rem 0.5rem;
    width: 96%;
    border: none;
    &, label{
      font-family: Book;
      letter-spacing: $base-letter-spacing;
      @include fontSize($text-font-size);
      line-height: 1.35em;
      text-transform: none;
    }
    background-color: $light-grey;
    margin: 0;
    height: rem-calc(30);
    box-sizing: content-box;
    margin-bottom: rem-calc(24);
    position: relative;
    .select-box__icon{
      position: absolute;
      right: 12px;
      top: 2px;
    }
  }
}
