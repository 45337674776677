/* Book */
@font-face {
  font-family: 'Book';
  font-weight: normal;
  src: url('../fonts/MaisonNeueWEB-Book.woff2') format('woff2'), url('../fonts/MaisonNeueWEB-Book.woff') format('woff');
}
/* Book Italic */
@font-face {
  font-family: 'Book';
  src: url('../fonts/MaisonNeueWEB-BookItalic.woff2') format('woff2'), url('../fonts/MaisonNeueWEB-BookItalic.woff') format('woff');
  font-weight: normal;
  font-style: italic;
}
/* Medium */
@font-face {
  font-family: 'Medium';
  font-weight: normal;
  src: url('../fonts/MaisonNeueWEB-Medium.woff2') format('woff2'), url('../fonts/MaisonNeueWEB-Medium.woff') format('woff');
}
/* Medium Italic */
@font-face {
  font-family: 'Medium';
  src: url('../fonts/MaisonNeueWEB-MediumItalic.woff2') format('woff2'), url('../fonts/MaisonNeueWEB-MediumItalic.woff') format('woff');
  font-weight: normal;
  font-style: italic;
}
/* Demi */
@font-face {
  font-family: 'Demi';
  font-weight: normal;
  src: url('../fonts/MaisonNeueWEB-Demi.woff2') format('woff2'), url('../fonts/MaisonNeueWEB-Demi.woff') format('woff');
}
/* Demi Italic */
@font-face {
  font-family: 'Demi';
  src: url('../fonts/MaisonNeueWEB-DemiItalic.woff2') format('woff2'), url('../fonts/MaisonNeueWEB-DemiItalic.woff') format('woff');
  font-weight: normal;
  font-style: italic;
}
/* Afrika Semi Bold */
@font-face {
  font-family: 'AfrikaSemiBold';
  src: url('../fonts/AfrikaSemiBold-Regular.woff2') format('woff2'), url('../fonts/AfrikaSemiBold-Regular.woff') format('woff');
}
