.collection-list-component{
  .collection-content{
    @media #{$medium-up} {
      margin-left: rem-calc(-22.5);
      margin-right: rem-calc(-22.5);
    }
    .collection-entry{
        margin-bottom: rem-calc(12);
        @media #{$medium-up} {
          padding-left: rem-calc(22.5);
          padding-right: rem-calc(22.5);
          margin-bottom: $default-margin-bottom;
        }
      .collection-entry-content{
        cursor: pointer;
        position: relative;
        border: 1.5px solid rgba(214,214,214, .6);
        height: 100%;
        .collection-entry-content-image{
          background-image: linear-gradient(to top, #fff 0%, #f6f6f6 80%);
          flex-grow: 1;
          img{
            mix-blend-mode: multiply;
          }
        }
        .collection-entry-content-text{
          padding: rem-calc(18);
          p, a, li, ol{
            font-family: Book;
            letter-spacing: $base-letter-spacing;
            @include fontSize($small-font-size);
            line-height: 1.2em;
          }
          @media #{$medium-down} {
            h5{
              margin: 0;
            }
          }
        }
        &:hover{
          &:after{
            content: '';
            position: absolute;
            left: 0;
            right: 0;
            top: 0;
            bottom: 0;
            background-color: rgba(129,124,121, .2);
          }
        }
      }
      .collection-detail{
        display: none;
      }
    }
  }
  .collection-filter{
    border: none;
    justify-content: flex-start;
    margin-bottom: $default-margin-bottom;
    @media #{$medium-up} {
      margin-left: rem-calc(-22.5);
      margin-right: rem-calc(-22.5);

      .filter-item-wrapper{
        padding-left: rem-calc(22.5);
        padding-right: rem-calc(22.5);
      }
    }
    @media #{$medium-down} {
      margin-bottom: rem-calc(12);
    }

    .select-box{
      border: 1px solid $black;
    }
  }
}

@media #{$medium-down} {
  .fancybox-slide--html{
    padding: 0;
  }
}
.fancybox-button svg path{
  fill: $white;
}
.collection-detail{
  @media #{$medium-down} {
    width: 100%;
    height: 100%;
  }
  @media #{$medium-up} {
    max-width: rem-calc(1100);
    height: 94vh;
    max-height: rem-calc(1080);
  }
  padding: 0;
  background-color: $light-grey;
  display: flex;
  .collection-detail-container{
    @media #{$medium-up} {
      height: 100%;
    }
    //-webkit-align-items: flex-start;
    //align-items: flex-start;
    @media #{$medium-down} {
      @include flexBoxes(flex-start, center, column-reverse, nowrap);
    }
  }
  .collection-detail-left{
    @media #{$medium-up} {
      width: 60%;
    }
    position: relative;
    .collection-detail-top-image{
      position: relative;
      background-color: $white;
    }
    .gallery-actions{
      position: absolute;
      bottom: 0;
      width: 100%;
      background-color: rgba(255,255,255,.7);
      padding: rem-calc(6);

      .collection-detail-actions, .collection-gallery-actions{
        -webkit-align-items: center;
        align-items: center;
      }
      svg{
        display: block;
      }
    }
  }

  &:not(.fullscreen){
    .collection-detail-left {
      //min-height: rem-calc(780);
      .tns-item {
        height: 100%;
        //max-height: rem-calc(780);
        max-height: 80vh;
        img{
          //max-height: rem-calc(780);
          max-height: 80vh;
        }
      }
    }
  }

  .collection-detail-left{
    .detail-action{
      &.information-action{
        display: none;
      }
      @media #{$medium-down} {
        &.fullscreen-action{
          display: none;
        }
      }
    }
    .collection-gallery-wrapper {
      .tns-inner {
        position: relative;
      }

      .tns-item {
        position: relative;
        img{
          transition: all 300ms;
          transform: scale(1);
          width: auto;
          margin: auto;
        }
        &:after{
          content: ' ';
          position: absolute;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
        }

        &.active-zoom {
          overflow: visible;
          img{
            position: relative;
            cursor: grab;
            transform: scale(1.5);
            -webkit-transform: scale(1.5);
          }
          &:active {
            cursor: grabbing;
          }
        }
        &.zoom-x2 {
          img {
            transform: scale(2);
            -webkit-transform: scale(2);
          }
        }

      }
    }
    .gallery-actions{
      .detail-action, .gallery-zoom{
        padding: 0 rem-calc(4);
      }
      .gallery-zoom{
        &.disable{
          opacity: .4;
          cursor: default;
        }
      }
    }
    .collection-gallery-navigation{
      @include fontSize($small-font-size);
      line-height: 1.2em;
      -webkit-align-items: center;
      align-items: center;
      padding: 0 rem-calc(24);
      .gallery-arrow{
        border: none;
        background: none;
        cursor: pointer;
        svg{
          width: $arrow-default-width*.4;
        }
        &.gallery-prev{
          left: -12px;
          -webkit-transform: scaleX(-1);
          transform: scaleX(-1);
        }
        &.gallery-next{
          right: -12px;
        }
      }
    }
  }

  .collection-detail-right{
    @media #{$medium-up} {
      width: 40%;
      min-height: 100%;
    }
    background-color: rgb(129,124,121);
    color: $white;
    padding: rem-calc(16) rem-calc(24);
    @media #{$medium-down} {
      padding-bottom: 0.4rem;
    }
    .detail-text{
      max-width: 100%;
      h2{
        letter-spacing: 0.04rem;
        line-height: 1.2em;
        margin-top: 0;
        word-break: break-word;
      }
      p{
        @include fontSize(16);
        @media #{$medium-down} {
          font-family: Book, Arial, sans-serif;
          letter-spacing: 0.01rem;
          line-height: 1.35em;
          font-size: 0.936rem;
        }
      }
    }
    .detail-infos{
      border-top: $border-width solid $white;
      div{
        border-bottom: $border-width solid $white;
        font-family: Book, Arial, sans-serif;
        letter-spacing: $base-letter-spacing;
        @include fontSize(12.8);
        line-height: 1.2em;
        padding: 3px 0;
        @media #{$medium-down} {
          letter-spacing: 0.01rem;
          line-height: 1.35em;
          font-size: 0.72rem;
        }
        span.label{
          font-family: Medium;
        }
      }
    }
    .social-links{
      justify-content: flex-end;
      -webkit-justify-content: flex-end;
      padding-top: rem-calc(24);
    }
  }
  .collection-detail-bottom{
    background-color: $light-grey;
    padding: rem-calc(12);
    .collection-thumbnails-wrapper{
      position: relative;
      .slide{
        cursor: pointer;
        display: inline-block;
        opacity: .8;
        &.tns-nav-active, &:hover{
          opacity: 1;
        }
      }
      .gallery-arrow{
        position: absolute;
        border: none;
        background: none;
        top: 0;
        height: 100%;
        padding: 0 rem-calc(4);
        cursor: pointer;
        svg{
          width: $arrow-default-width*.3;
        }
        &.gallery-prev{
          left: -12px;
          -webkit-transform: scaleX(-1);
          transform: scaleX(-1);
        }
        &.gallery-next{
          right: -12px;
        }
      }
    }
  }
  .collection-related-wrapper{
    margin-top: rem-calc(48);
    p.no-margin{
     margin: 0;
    }
    .collection-related{
      margin-left: rem-calc(-6);
      margin-right: rem-calc(-6);
    }
    .collection-related-item-wrapper{
      width: 20%;
      padding: rem-calc(6);
      @media #{$medium-down} {
        width: 50%;
      }
      .collection-related-item{
        padding: rem-calc(8);
        height: 100%;
        background-color: $white;
      }
    }
  }

  $fullscreenActionLayoutWidth: rem-calc(800);
  &.fullscreen{
    max-width: 100%;
    width: 100%;
    height: 100%;
    .collection-detail-left{
      width: 100%;
      height: 100%;
    }
    .collection-detail-right{
      width: 0;
      padding: 0;
    }
    .collection-detail-top-image{
      width: 100%;
      margin: auto;
      .gallery-actions{
        position: fixed;
        max-width: $fullscreenActionLayoutWidth;
        left: 50%;
        transform: translateX(-50%);
        background: $white;
        border: $border-width solid $border-color;
        border-bottom: none;
        padding: rem-calc(8);

        .detail-action{
          &.information-action{
            display: block;
          }
        }
      }
    }
    .collection-detail-top-text{
      position: absolute;
      bottom: 0;
      left: 50%;
      transform: translateX(-50%);
      display: none;
      padding: rem-calc(16);
    }
    .collection-detail-bottom{
      display: none;
    }

    .tns-item{
      img{
        width: 100%;
      }
    }

    &.show-detail{
      .collection-detail-top-text{
        position: fixed;
        bottom: rem-calc(52);
        display: block;
        background-color: $white;
        border: $border-width solid $border-color;
        width: 100%;
        color: $black;
        max-width: $fullscreenActionLayoutWidth;
        .detail-infos{
          &, div{
            border-color: $border-color;
          }
        }
      }
    }
  }
}

.collection-teasers{
  .collection-teaser-entry{
    .collection-teaser-entry-content{
      position: relative;
      @media #{$medium-down} {
        font-size: 0.936rem;
        line-height: 1.35em;
        .link-label{
          font-weight: bold;
        }
      }
      .collection-teaser-entry-img{
        border: 1.5px solid #e5e5e5;
        background-image: linear-gradient(to top, #fff 0%, #f6f6f6 80%);
        @media #{$medium-down} {
          margin-bottom: 6px;
        }
        img{
          mix-blend-mode: multiply;
        }
      }
      p:first-child{
        margin-top: 0;
      }
    }
    a{
      @include fontSize($small-font-size);
      line-height: 1.2em;
      span.link-label{
        border-bottom: 1px solid $black;
      }
      span.text{
        margin-right: rem-calc(8);
      }
      span.arrow-right{
        svg{
          width: rem-calc(5);
        }
      }
    }
  }
}