

#newsletter_signup{
  background-color: rgb(229,169,145);
  max-width: 760px;
  width: 100%;
  h2{
    margin-top: 0;
  }

  .form-element{
    margin-bottom: rem-calc(12);
    position: relative;
    input{
      &[type=text] {
        padding: rem-calc(6) rem-calc(8);
        width: 100%;
        border: none;
        font-family: Book;
        letter-spacing: $base-letter-spacing;
        @include fontSize($text-font-size);
        line-height: 1.35em;
      }
    }
  }
  .submit-element{
    font-family: Demi;
    letter-spacing: $base-letter-spacing;
    @include fontSize($text-font-size);
    line-height: 1.35em;
    text-transform: uppercase;
    .submitSearch{
      //display: none;
    }
  }

  .newsletter-infos-wrapper{
    border-top: 1px solid $black;
    margin-top: rem-calc(24);
  }
}