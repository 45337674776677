header{
  @include flexBoxes(space-between, flex-end, row, nowrap);
  padding-top: 22px;
  padding-bottom: rem-calc(20);
  position: relative;
    -webkit-box-shadow: 0px 11px 19px -4px rgba(0,0,0,0.39);
    -moz-box-shadow: 0px 11px 19px -4px rgba(0,0,0,0.39);
    box-shadow: 0px 11px 19px -4px rgba(0,0,0,0.39);
  @media #{$medium-down} {
    padding-top: rem-calc(12);
    padding-bottom: rem-calc(10);
    box-sizing: border-box;
    height: rem-calc(70);
    width: 100vw;
    padding-right: 0;
  }
  &:after{
    //content: '';
    //width: 100%;
    //top: calc(100% - 18px);
    //left: 0;
    //-webkit-box-shadow: 0px 10px 14px -4px rgba(0,0,0,0.18);
    //-moz-box-shadow: 0px 10px 14px -4px rgba(0,0,0,0.18);
    //box-shadow: 0px 10px 14px -4px rgba(0,0,0,0.18);
    //height: 18px;
    //position: absolute;
    //z-index: 1;
  }
  @media #{"only screen and (min-width: 1310px)"} {
    &:before, &:after {
      content: '';
      position: absolute;
      z-index: 1;
      top: 0;
      height: 100%;
      width: 30px;
      background: $white;
    }
    &:before {
      left: -15px;
    }
    &:after {
      right: -15px;
    }
  }

  // Navigation
  #logo{
    width: 170px;
    @media #{$medium-down} {
      width: auto;
      &, > a, img{
        height: 100%;
      }
    }
    img{
      display: block;
    }
  }
  #header_navigation{
    @include flexBoxes(space-between, center, row, nowrap);
    @media #{$medium-down} {
      @include flexBoxes(flex-start, center, row, nowrap);
      position: absolute;
      top: 100%;
      left: 0;
      -webkit-flex-direction: column;
      flex-direction: column;
      background-color: $white;
      width: 100%;
      z-index: 1;
      -webkit-align-items: flex-start;
      align-items: flex-start;
      padding: rem-calc(8) 0;
      &:not(.open){
        display: none;
      }
      &.fixed{
        position: fixed;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
      }
    }
  }
  nav{
    //padding-bottom: rem-calc(6);
    @media #{$medium-down} {
      width: 100%;
      padding-bottom: rem-calc(0);
      &.active-elt{
        li:not(.active):not(.open){
          a{
            color: $dark-grey;
          }
        }
      }
    }
    a{
      color: $black;
      //outline: none !important;
      outline: #fffefd solid 2px;
      border: 1px solid transparent;
    }
    &:hover{
      @media #{$medium-up} {
        a:not(:hover){
          color: $dark-grey;
        }
      }
    }
    &:focus-within{
      a:not(:focus){
        color: $dark-grey;
      }
    }
    .sub-menu-wrapper {
      display: none;
      @media #{$medium-up} {
        position: absolute;
        z-index: 2;
        width: 100%;
        left: 0;
        right: 0;
        top: calc(100% - 2px);
        background: $white;
      }
      .sub-menu {
        @include flexBoxes(flex-start, flex-start, row, nowrap);
        border-top: 1px solid $black;
        width: 100%;
        @media #{$medium-up} {
          padding: rem-calc(24) 0;
          margin: 0 rem-calc(45);
          position: relative;
        }

        > .nav {
          width: 33.33%;
          padding-left: rem-calc(30);
          @media #{$medium-down} {
            width: 100%;
            padding-left: rem-calc(24);
          }
        }

        li {
          line-height: 1.0em;
          @media #{$medium-down} {
            > a {
              padding: rem-calc(12) rem-calc(3);
            }
            &.open{
              >.sub-menu-arrow{
                transform: rotate(180deg);
              }
              > a{
                color: $black;
              }
            }
          }
          @media #{$large-up} {
            &.active, &.open{
              >.sub-menu-arrow{
                transform: rotate(90deg);
              }
              > a{
                color: $black;
              }
            }
          }
          @media #{$medium-down} {
            &:not(:nth-last-child(2)){
              a{
                border-bottom: 1px solid $black;
              }
            }
          }
          a {
            display: block;
            &:hover{
              &:after{
                content: '';
                width: 66.66%;
                height: 100%;
                display: block;
                position: absolute;
                right: 0;
                top: 0;
              }
            }
          }
          &.has-suv-nav{
            position: relative;
            .sub-nav-prefix{
              position: absolute;
              left: 0px;
              padding: 6px 3px;
              @media #{$medium-down} {
                padding: 12px 3px;
              }
            }
            .sub-nav-status{
              position: absolute;
              background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 30'%3E%3Cg id='arrow_right' data-name='Arrow Right'%3E%3Cpolygon fill='%231D1D1B' points='0 0.7 0.7 0 16 15 0.7 30 0 29.3 14.5 15 0 0.7'/%3E%3C/g%3E%3C/svg%3E");
              background-repeat: no-repeat;
              background-position: center;
              width: rem-calc(10);
              height: rem-calc(18.75);
              top: rem-calc(7);
              left: rem-calc(-16);
              @media #{$medium-down} {
                left: rem-calc(-24);
              }
            }
            &.active{
              .sub-nav-status{
                transform: rotate(90deg);
              }
            }

            > ul{
              display: none;
              margin-left: 24px;
              @media #{$medium-down} {
                margin-left: rem-calc(0);
                > li{
                  > a{
                    padding-left: rem-calc(32);
                  }
                }
              }
            }
            //&:hover, &:focus, &:active, &.active{
            &.open{
              > ul{
                display: block;
              }
            }
            @media #{$large-up} {
              &.active{
                > ul{
                  display: block;
                }
              }
            }
          }
        }

        > .menu-highlight {
          width: 66.66%;
          @media #{$medium-down} {
            display: none !important;
          }

          .menu-highlight-content {
            &:not(.open) {
              display: none;
            }

            .highlight-image-wrapper {
              box-sizing: border-box;

              &:first-of-type {
                padding-right: rem-calc(18);
              }

              &:last-of-type {
                padding-left: rem-calc(18);
              }

              .highlight-image {
                width: 100%;
                height: rem-calc(280);
                background-size: cover;
                background-position: center;
              }
            }
          }
        }
      }
    }
    > ul{
      @include flexBoxes(flex-start, flex-start, row, nowrap);
      @media #{$medium-down} {
        -webkit-flex-direction: column;
        flex-direction: column;
        width: 100%;
        padding: 0 rem-calc(8);
        box-sizing: border-box;
        .sub-menu-wrapper{
          display: none;
        }
      }
      >li{
        @media #{$medium-down} {
          width: 100%;
          border-bottom: 1px solid $black;
        }
         &, > a{
           @include fontSize(25);
           padding: rem-calc(0) rem-calc(8);
           font-family: Book;
           letter-spacing: 0.04em;
           font-weight: bolder;
           @media #{$medium-down} {
             font-weight: normal;

           }
           @media only screen and (max-width: 1280px) {
             padding: .625vw;
           }
           @media #{$medium-down} {
             padding: rem-calc(4) rem-calc(0);
           }
        }
        > a:focus ~ div.sub-menu-wrapper {
          display: flex;
          .sub-menu {
            z-index: 10;
          }
        }
        @media #{$large-up} {
          &:hover, &:focus, &:focus-within {
            > a {
              color: $black !important;
            }
            > .sub-menu-wrapper{
              display: flex;
              @media #{$medium-up} {
                box-shadow-webkit-box-shadow: 0px 10px 18px -4px rgba(0, 0, 0, 0.18);
                -moz-box-shadow: 0px 10px 18px -4px rgba(0, 0, 0, 0.18);
                box-shadow: 0px 10px 18px -4px rgba(0, 0, 0, 0.18);
              }
              .sub-menu{
                z-index: 10;
              }
            }
            &:before{
              content: '';
              width: 100%;
              height: 30px;
              position: absolute;
              left: 0;
              top: calc(100% - 28px);
              z-index: 2;
            }
          }
        }
        @media #{$medium-down} {
          &.has-suv-nav{
            position: relative;
            &:after {
              //content: '';
              position: absolute;
              background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 16'%3E%3Cg class='arrow-down' width='15' hieght='8' data-name='Arrow Down'%3E%3Cpolygon fill='%231D1D1B' points='29.3 0 30 0.7 15 16 0 0.7 0.7 0 15 14.5 29.3 0'/%3E%3C/g%3E%3C/svg%3E");
              width: rem-calc(18.75);
              height: rem-calc(10);
              top: 8px;
              right: 4px;
            }
            &.open{
              &:after {
                transform: rotate(180deg);
              }
            }
          }
          .sub-menu-arrow{
            transition: all .3s;
            width: 48px;
            height: 43px;
            position: absolute;
            right: 0px;
            top: 0px;
            padding: 0 12px;
            svg{
              top: 50%;
              width: 22px;
              position: absolute;
              transform: translateY(-50%);
            }
          }
          &.open{
            > .sub-menu-wrapper{
              display: flex;
            }
            >.sub-menu-arrow{
              @media #{$medium-down} {
                transform: rotate(180deg);
              }
            }
          }
        }
      }
    }
    li{
      list-style: none;
      margin: 0;
      a{
        padding: 6px 3px;
      }
    }
  }

  // Touch Navigation
  #touch_menu {
    display: none;
    @media #{$medium-down} {
      display: flex;
      outline: 0;
    }
    #touch_menu_close{
      display: none;
    }
    position: relative;
    text-transform: uppercase;
    width: 30px;
    //height: 14px;
    padding: .8em 1em;
    padding-bottom: rem-calc(8);
    align-items: center;
    justify-content: center;
    svg{
      display: block;
    }
    > div {
      //position: relative;
      //flex: none;
      //width: 100%;
      //height: 2px;
      //background: $black;
      //transition: all .4s ease;
      //display: flex;
      //align-items: flex-end;
      //justify-content: center;
      //margin-top: .5em;
      //
      //&:before{
      //  content: '';
      //  position: absolute;
      //  z-index: 1;
      //  top: -10px;
      //  left: 0;
      //  width: 100%;
      //  height: 2px;
      //  background: inherit;
      //  transition: all 0.4s ease;
      //}
    }

    &.selected{
      //transform: rotate(135deg);
      //> div {
      //  &:before,
      //  &:after {
      //    top: 0;
      //    transform: rotate(90deg);
      //  }
      //  &:after {
      //    opacity: 0;
      //  }
      //}
      #touch_menu_open{
        display: none;
      }
      #touch_menu_close{
        display: block;
      }
    }
  }
  #nav_lang{
    a{
      display: inline-block;
    }
  }

  &:not(.fixed){
    #mobile_open_nav{
      &.fixed{
        position: fixed;
        left: 50%;
        transform: translateX(-50%);
        top: 0;
        z-index: 100;
        background: rgba(255,255,255,.8);
      }
    }
  }
  &.fixed{
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 100;
    #header_navigation{
      height: calc(100vh - 70px);
      overflow: auto;
      #nav_lang{
        padding-bottom: 50px;
      }
    }
  }
}