.author {
  &.rightCol {
    border-bottom: 1px solid $black;
    text-align: center;
  }
  &.light{
    border-top: 1px solid $black;
    text-align: right;
  }
  & .author-image {
    display: block;
    margin: 0 auto rem-calc(17) auto;
    border-radius: 50%;
    height: rem-calc(80);
    width: rem-calc(80);
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;

  }

  & h2.name {
    text-align: center;
    //@include font-family(blogTextBold, $blogTextSize, $blogTextSize* 1.6, $black);
  }

  & p.profession {
    font-family: Book;
    text-transform: uppercase;
    letter-spacing: $base-letter-spacing;
    @include fontSize($small-font-size);
    line-height: 1.17em;
    text-align: center;
    margin-bottom: rem-calc(12);
  }

  &.rightCol {
    & .author-btn {
      display: inline-block;
      //margin: 0 auto rem-calc(12) auto;
      border: none;
      height: rem-calc(14);
      width: rem-calc(28);
      & svg {
        max-height: 100%;
        width: auto;
        display: block;
      }
    }
  }

  &.light {
    margin-bottom: rem-calc(65);
    & h2.name {
      //@include font-family(blogText, ($blogTextSize - 2), ($blogTextSize - 2) * 1.6, $black);
      text-align: right;
      margin-bottom: rem-calc(5);

      @media #{$small-only} {
        visibility: hidden;
      }
    }
  }

  &.full {
    border-bottom: none;
    margin-bottom: rem-calc(90);
    & .author-image {
      width: rem-calc(140);
      height: rem-calc(140);
    }
    & .presentation {
      position: relative;
      margin-top: rem-calc(65);
      padding-top: rem-calc(65);
      //@include font-family(blogText, ($blogTextSize - 2), ($blogTextSize - 2) * 1.6, $black);
      text-align: center;
      &:before {
        content:"";
        position: absolute;
        top: -1px;
        width: rem-calc(265);
        left: 50%;
        transform: translateX(-50%);
        border-top: 1px solid $black;
      }
    }

  }

}
h2.entries-title {
  //@include font-family(blogTextBold, $blogH2Size, $blogH2Size * 1.6, $black);
  text-align: center;
  margin-bottom: rem-calc(120);

  @media #{$small-only} {
    margin-bottom: rem-calc(80);
  }
}
