.boxs{
  @media #{$medium-up} {
    margin-left: rem-calc(-22.5);
    margin-right: rem-calc(-22.5);
  }
  .box-link-component{
    padding-bottom: $default-margin-bottom*.6;
    @media #{$medium-up} {
      padding-left: rem-calc(22.5);
      padding-right: rem-calc(22.5);
      padding-bottom: $default-margin-bottom;
    }
    @media #{$small-only} {
      padding-bottom: rem-calc(22.5);
    }

    h5{
      margin-bottom: 0;
    }

    .box-link-wrapper{
      position: relative;
      //height: rem-calc(256);
      height: 0;
      padding-bottom: 56.25%;
      .box-link-content {
        //opacity: 0;
        position: absolute;
        background-color: #e9f5f2;
        padding: rem-calc(16) rem-calc(12);
        height: 100%;
        width: 100%;
        overflow: auto;

        //hide Scrollbars
        -ms-overflow-style: none;  /* IE and Edge */
        scrollbar-width: none;  /* Firefox */
        &::-webkit-scrollbar {
          display: none;
        }

        p:first-of-type{
          margin-top: 0;
        }
        p{
          font-family: Book;
          @include fontSize($footer-text-font-size);
          line-height: 1.3em;
          @media #{$medium-down} {
            font-size: rem-calc(14);
          }
          strong{
            font-family: Demi;
            @include fontSize($small-font-size);
            @media #{$medium-down} {
              font-size: rem-calc(16);
            }
          }
        }
        .arrow-right{
          position: absolute;
          bottom: 0;
          right: rem-calc(12);
          width: rem-calc(18);
          height: rem-calc(48);
        }
      }

      .box-link-image{
        position: absolute;
        opacity: 1;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        background-size: cover;
        background-position: center;
      }

      &:not(.no-text){
        &:hover{
          .box-link-image{
            opacity: 0;
          }
        }
      }
    }

    span.arrow-right{
      display: inline-block;
      svg{
        width: 8px;
        display: block;
      }
    }
    span.type{
      text-transform: uppercase;
    }

    &.boxLastBlogPost{
      .row-layout{
        -ms-flex-direction: column;
        -webkit-flex-direction: column;
        flex-direction: column;
      }
    }
    @media #{$medium-down} {
      &.event-box-component {
        .box-link-image {
          opacity: 0;
        }
      }
    }
  }
}
