// Exhibition
#exhibition_header_wrapper{
  @media #{$large-up} {
    padding: rem-calc(18) 0;
    font-family: Demi;
  }
  @media #{$medium-down} {
    padding: rem-calc(22) 0 rem-calc(18) 0;
    .open-now-content{
      color: $dark-grey;
      text-align: center;
      font-size: rem-calc(18);

    }
  }
}
@media #{$medium-down} {
  .exhibition-content{
    margin-top: rem-calc(24);
    h4{
      font-size: rem-calc(16);
      margin: rem-calc(18) 0;
    }
  }
}
.exhibition-cta-link{
  &, a{
    font-family: AfrikaSemiBold;
  }
}

.full-width-wrapper{
  .tns-outer{
    @media #{$medium-down} {
      padding-bottom: rem-calc(12);
      margin-left: -12px;
      margin-right: -12px;
    }
    @media #{$large-up} {
      padding-bottom: rem-calc(24);
      margin-left: -45px;
      margin-right: -45px;
    }
  }
}
.exhibition-wrapper{
  position: relative;
  .full-width-wrapper{
    padding-bottom: rem-calc(24);
  }
  #colright{
    margin-bottom: $default-margin-bottom;
    @media #{$medium-down} {
      margin-bottom: rem-calc(24);
    }
    .exhibition-cta-link{
      &, a {
        @include fontSize(24);
        line-height: rem-calc(28);
        margin-bottom: rem-calc(24);
        font-family: AfrikaSemiBold;
      }
    }
    .social-links{
      position: absolute;
      top: rem-calc(8);
      right: 0;
      @media #{$medium-down} {
        top: 0;
      }

      li{
        img{
          height: rem-calc(14);
        }
      }
    }
    .museum-infos, .item-list, .downloads, .components, .contact-infos, .texts{
      padding-top: rem-calc(6);
      padding-bottom: rem-calc(6);
      border-top: 1px solid $black;
    }
    h3{
      margin-top: rem-calc(12);
      margin-bottom: rem-calc(12);
      text-transform: uppercase;
    }
    .item-list{
      margin-bottom: 0;
      .event-short-list-entry{
        margin-bottom: rem-calc(12);
        position: relative;
        a{
          color: $black;
        }
        .evt-date{
          font-family: Demi;
          letter-spacing: $base-letter-spacing;
          @include fontSize(19.2);
          line-height: 1.17em;
        }
      }
    }
    .contact-infos{
      strong{
        font-family: Demi;
        letter-spacing: $base-letter-spacing;
        @include fontSize(19.2);
        line-height: 1.17em;
      }
    }
  }
}


// Exhibition Liste
.exhibition-list{
  border-top: 1px solid $black;
  margin-top: rem-calc(22);
  margin-bottom: rem-calc(48);
  .item-list-entry{
    position: relative;
    border: none;
    @media #{$medium-down} {
      h2{
        padding-top: rem-calc(6);
      }
      h5{
        margin: rem-calc(6) 0;
      }
    }
    .list-item-link{
      //border-bottom: 1px solid $black;
      color: $black;
      .item-img-detail{
        img{
          max-width: 100%;
        }
      }
      .list-item-text{
        height: 100%;
        padding: rem-calc(12) rem-calc(24);
      }
    }
  }
}
