@charset "UTF-8";

// Mixins/Default values
$border-size: 2px;

@mixin vertical-align {
    position: relative;
    top: 50%;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
}

@mixin background-opacity($color, $opacity: 0.3) {
  background: $color; /* The Fallback */
  background: rgba($color, $opacity);
}

@mixin flexBoxes($justify:center, $align:center, $direction:row, $wrap:nowrap) {
  display: -moz-box;              /* OLD - Firefox 19- (doesn't work very well) */
  display: -ms-flexbox;           /* TWEENER - IE 10 */
  display: -webkit-flex;          /* NEW - Chrome */
  display: flex;                  /* NEW, Spec - Opera 12.1, Firefox 20+ */

  -ms-flex-direction: $direction;
  -webkit-flex-direction: $direction;
  flex-direction: $direction;

  -ms-flex-wrap: $wrap;
  -webkit-flex-wrap: $wrap;
  flex-wrap: $wrap;

  -webkit-align-items: $align;
  align-items: $align;

  -webkit-justify-content: $justify;
  justify-content: $justify;
}

$pxToVwRatio: 1.25/16;
@mixin fontSize($font-size) {
  font-size: rem-calc($font-size);
  @media only screen and (max-width: 1280px) {
    font-size: $font-size*$pxToVwRatio+vw;
  }
  @media #{$medium-down} {
    font-size: rem-calc($font-size *.72);
  }
}