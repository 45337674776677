.rowsComponent, .row-layout {
  @include flexBoxes(flex-start, stretch, row, wrap);

  &.vertical {
    -ms-flex-direction: column;
    -webkit-flex-direction: column;
    flex-direction: column;
  }

  &.nowrap {
    -ms-flex-wrap: nowrap;
    -webkit-flex-wrap: nowrap;
    flex-wrap: nowrap;
  }

  &.space-between {
    -webkit-justify-content: space-between;
    justify-content: space-between;
  }

  &.justify-center {
    -webkit-justify-content: center;
    justify-content: center;
  }

  &.space-evenly {
    -webkit-justify-content: space-evenly;
    justify-content: space-evenly;
  }

  &.align-center{
    -webkit-align-items: center;
    align-items: center;
  }

  .row-space{
    flex-grow: 1;
  }

  .medium-2 {
    width: 16.66667%;
  }

  .medium-3 {
    width: 25%;
  }

  .medium-4 {
    width: 33.33%;
  }

  .medium-5 {
    width: 41.66667%;
  }

  .medium-6 {
    width: 50%;
  }

  .medium-7 {
    width: 58.33%;
  }

  .medium-8 {
    width: 66.66%;
  }

  .medium-9 {
    width: 75%;
  }

  .medium-10 {
    width: 83.33%;
  }
  @media #{$small-only} {
    .medium-2, .medium-3, .medium-4, .medium-5, .medium-6, .medium-7, .medium-8, .medium-9, .medium-10 {
      width: 100%;
    }
  }
}
.rowsComponent{
  @media #{$large-up} {
    margin-left: rem-calc(-12);
    margin-right: rem-calc(-12);
  }
  .columns{
    padding: 0 rem-calc(22.5);
    box-sizing: border-box;
    @media #{$small-only} {
      width: 100%;
      padding: 0;
    }
  }
}