footer{
  margin-top: rem-calc(12);
  position: relative;
  .footer-row{
    border-top: 1px solid $black;
    padding: rem-calc(12) 0;
  }
  @media #{$medium-down} {
    .social-links{
      li:first-of-type{
        padding: 0;
      }
    }
  }
  #footer_nav{
    @media #{$medium-down} {
      display: none;
    }
    padding-right: rem-calc(72);
    ul{
      list-style: none;
      margin: 0;
      a{
        color: $black;
        display: block;
      }
      li.has-suv-nav{
        li{
          margin: 2px 0;
        }
      }
    }
    > ul {
      @include flexBoxes(space-between, flex-start, row, nowrap);
      > li > a{
        font-family: Demi;
        letter-spacing: $base-letter-spacing;
        @include fontSize(19);
        line-height: 1.35em;
        margin-bottom: rem-calc(7.2);
        display: block;
      }
    }
  }
  #footer_infos{
    .footer-infos-column{
      a{
        text-decoration: underline;
      }
      &:not(.column-img){
        flex-grow: 1;
        @media #{$medium-down} {
          width: 60%;
        }
      }
      &.column-img{
        width: rem-calc(150);
        margin-left: rem-calc(15);
        @media #{$medium-down} {
          position: absolute;
          right: 12px;
          top: 24px;
        }
        img{
          border-radius: rem-calc(30);
          border: 1px solid $main-color;
        }
      }
    }
  }
  #toTop{
    position: absolute;
    width: rem-calc(24);
    right: rem-calc(48);
    display: block;
    @media #{$large-up} {
      top: rem-calc(12);
    }
    @media #{$medium-down} {
      &.fixed{
        background: rgba(255, 255, 255, 0.8);
        z-index: 1;
        padding: 12px;
        bottom: 0;
        position: fixed;
      }
    }
  }
  #footer_actions{
    @media #{$medium-up} {
      @include flexBoxes(space-between, center, row, nowrap);
    }
    @media #{$medium-down} {
      @include flexBoxes(flex-start, flex-start, column, nowrap);
    }
    a{
      text-transform: uppercase;
      font-family: Demi;
    }
  }

  #footer_newsletter_signup{
    @media #{$medium-down} {
      margin-bottom: rem-calc(24);
      margin-top: rem-calc(14);
    }
    .grey-underline{
      border-bottom: 1px solid $dark-grey;
      font-family: Book;
      //color: $dark-grey;
      text-transform: capitalize;
    }
  }
}