.teaser-container{
  position: relative;
  //margin-left: -45px;
  //margin-right: -45px;
  margin-bottom: $default-margin-bottom;
  @media #{$medium-down} {
    margin-left: -12px;
    margin-right: -12px;
    margin-bottom: rem-calc(12);
  }
  .tns-outer{
    > button{
      display: none;
    }
  }
  .teaser-wrapper{
    width: 100vw;
    left: 50%;
    transform: translateX(-50%);
    position: relative;
  }
  .teaser-element{
    position: relative;
    img{
      width: 100%;
      @media #{$medium-down} {
        height: 100%;
        object-fit: cover;
      }
    }
    a{
      &:hover{
        cursor: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='68px' height='88px' viewBox='0 0 67.4 86'%3E%3Cg id='Layer_2' data-name='Layer 2'%3E%3Cg id='Ebene_1' data-name='Ebene 1'%3E%3Cpath fill='%23fff' stroke='%23231f20' stroke-miterlimit='10' stroke-width='1.52px' d='M54.8,39.6a5.9,5.9,0,0,1,5.9-5.9,5.8,5.8,0,0,1,5.9,5.9V60.5a24.7,24.7,0,0,1-45.1,14L2,47.9a6.8,6.8,0,0,1,1.5-9.4A6.8,6.8,0,0,1,12.9,40l5.7,8V7.1a6.4,6.4,0,0,1,12.7,0V28a5.9,5.9,0,0,1,5.9-5.9A6,6,0,0,1,43.1,28v5.7a5.9,5.9,0,1,1,11.7,0v5.9Z'/%3E%3C/g%3E%3C/g%3E%3C/svg%3E"), pointer;
      }
    }

    .teaser-element-content{
      position: absolute;
      top: 0;
      left: 50%;
      transform: translateX(-50%);
      width: 100%;
      //left: 0;
      //right: 0;
      bottom: 0;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      height: 100%;
      padding: rem-calc(45) 0;
      max-width: $max-width;
      @media #{$medium-down} {
        padding: rem-calc(6);
      }
      &.align-center{
        justify-content: center;
      }
      &.align-right{
        justify-content: right;
        text-align: right;
      }
      .teaserText{
        padding: rem-calc(16) rem-calc(45);
        @media #{$medium-down} {
          padding: rem-calc(8) rem-calc(6);
        }
      }
    }

    .title-teaser-slider{
      font-family: AfrikaSemiBold;
      @include fontSize(100);
      line-height:0.9em;
    }
    .subtitle-teaser-slider{
      font-family: AfrikaSemiBold;
      @include fontSize(60);
      line-height:0.9em;
    }

    .text-teaser-slider {
      font-family: Demi;
      font-weight: normal;
      letter-spacing: $base-letter-spacing;
      @include fontSize(24);
      line-height: 1.32em;
    }
  }
  .hexagon-wrapper {
    position: absolute;
    right: 3%;
    bottom: 7%;
    @media #{$medium-down} {
      display: none;
    }
    .hexagon {
      position: relative;
      width: 300px;
      height: 173.21px;
      transform: rotate(80deg);
      background-color: #a79069;
      margin: 86.60px 0;
      box-shadow: 0 0 20px rgba(0, 0, 0, 0.2);

      &:before,
      &:after {
        content: "";
        position: absolute;
        z-index: 1;
        width: 212.13px;
        height: 212.13px;
        -webkit-transform: scaleY(0.5774) rotate(-45deg);
        -ms-transform: scaleY(0.5774) rotate(-45deg);
        transform: scaleY(0.5774) rotate(-45deg);
        background-color: inherit;
        left: 43.9340px;
        box-shadow: 0 0 20px rgba(0, 0, 0, 0.2);
      }

      &:before {
        top: -106.0660px;
      }

      &:after {
        bottom: -106.0660px;
      }

      span {
        display: block;
        position: absolute;
        top: 0px;
        left: 0;
        width: 300px;
        height: 173.2051px;
        z-index: 2;
        background: inherit;
      }
    }
    .text {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      max-width: 80%;
      color: $white;
      width: 100%;
      padding: 0px rem-calc(8);
      //text-align: center;
      font-family: Demi;
      font-weight: normal;
      letter-spacing: $base-letter-spacing;
      @include fontSize(24);
      line-height: 1.32em;
    }
  }

  .social-links{
    justify-content: flex-end;
    -webkit-justify-content: flex-end;

    position: absolute;
    right: rem-calc(12);
    @media #{$medium-up} {
      bottom: 0;
    }
    @media #{$medium-down} {
      top: rem-calc(6.4);
    }

    li{
      margin-bottom: rem-calc(8);
    }
  }

  .pagination-wrapper{
    position: absolute;
    color: $white;
    bottom: rem-calc(26);
    left: rem-calc(12);
    letter-spacing: 0.4rem;
    @media #{$medium-up} {
      transform: translateX(-50%);
      left: 50%;
      bottom: rem-calc(2);
    }
  }

  .skip-component{
    position: absolute;
    bottom: 0;
    left: 50%;
    padding: rem-calc(24);
    transform: translateX(-50%);
  }

  .tns-inner{
    position: relative;
  }



  &.editMode{
    .teaser-element{
      width: 50%;
      height: auto;
      overflow: hidden;
      video{
        max-width: 100%;
      }
    }
  }
}