.tns-carousel:not(.grid-gallery){
  .tns-inner{
    overflow: hidden;
  }
}

.tns-nav{
  display: none;
  outline: none;
}

.tns-item{
  overflow: hidden;
  img{
    display: block;
  }
  video.background-video{
    position: absolute;
    min-width: 100%;
    min-height: 100%;
    height: 100%;
    left: 50%;
    transform: translateX(-50%);
  }
}

.controls-arrows{
  outline: none;
  .controls__prev, .controls__next{
    width: 32px;
    outline: none;
  }
}

.carousel-wrapper:not(.teaser-wrapper){
  position: relative;
  .controls-arrow{
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    background: none;
    border: none;
    &.controls__prev{
      left: rem-calc(-45);
      @media #{$medium-down} {
        left: rem-calc(0);
      }
    }
    &.controls__next{
      right: rem-calc(-45);
      @media #{$medium-down} {
        right: rem-calc(0);
      }
    }
  }
}

.related-carousel{
  &:not(.edit-mode){
    width: 70%;
    @media #{$medium-down} {
      width: 100%;
    }
  }
  margin: auto;
  margin-bottom: $default-margin-bottom;
  @media #{$medium-down} {
    padding: 0 rem-calc(36);
    //border-top: 1px solid $black;
    //margin-top: rem-calc(24);
    margin-bottom: rem-calc(12);
  }

  h4{
    @include fontSize($text-font-size);
    line-height: 1.35em;
    margin-top: rem-calc(12);
    margin-bottom: rem-calc(0);
    @media #{$medium-down} {
      margin-top: rem-calc(12);
      @include fontSize($small-font-size);
      line-height: 1.15em;
    }
  }

  p{
    @include fontSize($small-font-size);
    line-height: 1.17em;
    margin-top: rem-calc(6);
    margin-bottom: rem-calc(6);
    @media #{$medium-down} {
      display: none;
    }
  }
  .tns-carousel{
    padding-left: rem-calc(24);
    @media #{$medium-down} {
      padding-left: rem-calc(10);
    }
  }
  &.related-exhibition{
    .image-wrapper{
      margin-bottom: rem-calc(24);
    }
    .caption{
      @include fontSize(14);
    }
    h5{
      margin-top: 10px;
      font-weight: 500;
    }
  }
}

.grid-gallery{
  .carousel-item{
    height: 688px;
    @media #{$medium-down} {
      height: 50vh;
    }
    .item-wrapper{
      @include flexBoxes(space-between, flex-start, column, nowrap);
      height: 100%;
      > div:not(.space){
        width: 60vw;
        @media #{$medium-down} {
          width: 100vw;
        }
        max-width: 700px;
        flex-grow: 1;
        background-size: cover;
        background-position: center;
        background-color: $light-grey;
        position: relative;
        .grid-zoom-link{
          display: block;
          position: absolute;
          width: 100%; 
          height: 100%;
        }
      }
      .space{
        height: 10px;
      }
    }
  }
}

.teaser-wrapper, .grid-wrapper{
  .controls-arrow{
    position: absolute;
    height: 100%;
    width: 10% !important;
    border-radius: 0;
    background-color: transparent;
    border: none;
    top: 0;
    &.controls__next{
      right: 0;
      cursor: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='42px' height='94px' viewBox='0 0 41.6 92.1'%3E%3Cg id='Layer_2' data-name='Layer 2'%3E%3Cpath fill='%23fff' stroke='%23231f20' stroke-width='1.52px' d='M1.1,8.3,13.3,1,40.8,46.2,13.4,91,1.2,83.8,24.6,46.3Z'/%3E%3C/g%3E%3C/svg%3E%0A"), pointer;
    }
    &.controls__prev{
      cursor: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='42px' height='94px' viewBox='0 0 41.7 92'%3E%3Cg id='Layer_2' data-name='Layer 2'%3E%3Cpath fill='%23fff' stroke='%23231f20' stroke-width='1.52px' d='M17.1,46.3,40.5,83.8,28.3,91,.9,46.2,28.4,1,40.6,8.3Z'/%3E%3C/g%3E%3C/svg%3E%0A"), pointer;
    }
    svg{
      display: none;
    }
  }
}

.teaser-wrapper{
  .teaser-element{
    @media #{$medium-down} {
      height: calc(100vh - 70px);
    }
  }
}

.gallery-component{
  .carousel-item{
    height: 500px;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    @media #{$medium-down} {
      height: 30vw;
    }
    a{
      //display: inline;
      display: contents;
    }
    img{
      display: block;
      height: 90%;
      width: auto;
      max-width: 100%;
      object-fit: contain;
    }
    .caption{
      margin-top: rem-calc(6);
    }
    .embed-wrapper{
      display: block;
      width: 711px;
      max-width: 100%;
      height: 100%;
    }
  }
}