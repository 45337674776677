.collapsible{
  border-top: 1px solid $black;
  margin-bottom: $default-margin-bottom*3;
  .collapsible-entry{
    border-bottom: 1px solid $black;
    width: 100%;
    position: relative;
    &.sub-collapsible-entry{
      &:last-child{
        border-bottom: none;
      }
      .collapsible-title{
        &:hover{
          background-color: $light-grey;
          h2{
            color: $black;
          }
        }
      }
    }
    .collapsible-title{
      position: relative;
      cursor: pointer;
      padding-right: rem-calc(32);
      h2{
        margin: 0;
        padding: rem-calc(32) 0;
      }
      h3{
        margin: rem-calc(20) 0;
        //padding: rem-calc(20) 0;
      }
      span.arrow-right{
        position: absolute;
        right: 4px;
        top: 50%;
        transform: translateY(-50%);
        svg{
          transition: all .3s;
          width: rem-calc(24);
          height: rem-calc(10);
        }
      }
      &.active{
        .arrow-right svg{
          transform: rotateZ(-180deg);
        }
      }
      &:hover, &.active{
        h2{
          color: $dark-grey;
        }
      }
    }
    .collapsible-content{
      max-height: 0;
      overflow: hidden;
      transition: max-height 0.2s ease-out;
      &:not(.sub-collapsible-content){
        padding-left: 24px;
        margin-bottom: 8px;
        .collapsible-entry:not(.sub-collapsible-entry):first-child{
          &:before{
            content: '';
            border-top: 1px dashed #000;
            width: calc(100% + 24px);
            height: 1px;
            left: -24px;
            position: absolute;
          }
        }
      }
    }
  }
}

.collapsible-media{
  .exhibition-media-download-link{
    display: block;
    &:hover{
      background-color: $light-grey;
    }
    .exhibition-media-download{
      min-height: rem-calc(130);
      -webkit-align-items: center;
      align-items: center;
      .media-download-text{
        padding-top: 0;
        &:not(.no-image){
          padding-left: rem-calc(24);
        }
      }
    }
  }
  .sub-collapsible-entry{
    position: relative;
    &:first-child{
      border-top: 1px solid $black;
    }
    .sub-collapsible-item-wrapper{
      position: relative;
    }
    .sub-collapsible-item{
      border-bottom: 1px solid $black;
      position: relative;
      &:first-child{
        border-top: 1px solid $black;
      }
      &:last-child{
        border-bottom: none;
      }
    }
  }
  .media-download-image{
    width: rem-calc(170);
    min-height: rem-calc(130);
    background-size: auto 100%;
    background-repeat: no-repeat;
    background-position: center;
  }
  .media-download-date, .media-download-text{
    padding-top: rem-calc(24);
  }
  .media-download-date {
    padding-left: rem-calc(24);
    padding-right: rem-calc(24);
    &.no-image{
      padding-left: 0;
    }
  }
  .media-download-date, .media-download-link{
    width: 18%;
  }
  .media-download-text{
    box-sizing: border-box;
    width: calc(82% - 170px);
    line-height: rem-calc(28);
    &.no-image{
      width: 82%;
    }
  }
  .media-download-link{
    text-align: right;
    display: block;

    .row-layout{
      -webkit-align-items: center;
      align-items: center;
      -webkit-justify-content: center;
      justify-content: center;
      height: 100%;
    }
    .text{
      @include fontSize($small-font-size);
      line-height: 1.17em;
    }
    .download-arrow{
      width: 13px;
      transform: rotateZ(45deg);
      margin-right: 8px;
      svg{
        display: block;
      }
    }
  }
  .collapsible-title{
    &.active{
      //border-bottom: 2px dotted $black;
    }
  }
  .exhibition-media-download-link{
    .media-download-image, .media-download-link > .row-layout{
      border-left: 1px solid $black;
      border-right: 1px solid $black;
    }
    .media-download-image{
      -webkit-box-shadow: inset 0px 0px 0px 8px #fff;
      -moz-box-shadow: inset 0px 0px 0px 8px #fff;
      box-shadow: inset 0px 0px 0px 8px #fff;
    }
    .media-download-link{
      //position: relative;
      > .row-layout{
        position: absolute;
        height: 100%;
        width: 18%;
        top: 0;
        right: 0;
        .download-arrow{
          position: absolute;
          bottom: 8px;
          right: 4px;
        }
      }
    }
  }
}

.media-releases{
  .tab {
    overflow: hidden;
    border-top: 1px solid $black;
    button {
      background-color: inherit;
      float: left;
      border: none;
      outline: none;
      cursor: pointer;
      padding: rem-calc(8) rem-calc(16);
      transition: 0.3s;
      font-family: Demi;
      @include fontSize($small-font-size);
      &:hover, &.active {
        text-decoration: underline;
      }
      &:first-child{
        padding-left: 0;
      }
    }
  }
  .tabcontent{
    display: none;
    border-top: none;
  }
  .media-releases-date{
    font-family: Demi;
    @include fontSize(22);
    line-height: 1.4em;
    .year{}
    .date{
      font-family: Book;
      @include fontSize(42);
    }
    .time{}
  }
  .media-releases-detail{
    padding-left: 18%;
    width: 100%;
    .text{
      p:first-of-type{
        margin-top: 0;
      }
    }
    .media-releases-links{
      &, p, a, li, ol{
        @include fontSize($small-font-size);
        line-height: 1.2em;
      }
      list-style: none;
      margin-left: rem-calc(18);
      margin-top: $default-margin-bottom;
      margin-bottom: $default-margin-bottom;
      li{
      position: relative;
        &:before{
          content: '';
          position: absolute;
          left: rem-calc(-18);
          top: rem-calc(2);
          width: rem-calc(6.4);
          height: rem-calc(12);
          background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 30'%3E%3Cg id='arrow_right' data-name='Arrow Right'%3E%3Cpolygon fill='%231D1D1B' points='0 0.7 0.7 0 16 15 0.7 30 0 29.3 14.5 15 0 0.7'/%3E%3C/g%3E%3C/svg%3E");
          background-size: cover;
          background-position: center;
        }
        p{
          margin-bottom: $default-margin-bottom/5;
        }
        .download-arrow {
          display: none;
        }
      }
    }
  }
}

.editmode{
  .collapsible-content{
    .mgnlEditorBar.mgnlEditor{
      display: flex !important;
      min-height: rem-calc(35)!important;
    }
  }
}