
// Blog
#ocm-page, #owc-page{
  .col-header{
    margin-bottom: rem-calc(24);
  }
  #colleft{
    .scroll-directory{
      .title{
        border-top: 1px solid $black;
        padding: rem-calc(14) 0;
        @include fontSize($text-font-size);
        margin: 0;
      }
      .text{
        margin-bottom: rem-calc(14);
      }
      .level2-container{
        margin-left: rem-calc(26);
      }
      .level3-container{
        .text{
          margin-left: rem-calc(40);
        }
      }
    }
  }
  .aleph, a{
    &:hover{
      &, span{
        cursor: pointer;
        text-decoration: underline;
      }
    }
  }
  .sidebar{
    .scroll-index{
      border-bottom: 1px solid $black;
      .title{
        cursor: pointer;
        border-top: 1px solid $black;
        margin: rem-calc(2) 0;
        padding: rem-calc(6) 0;
      }
      .scroll-index-item{
        p, a, div {
          @include fontSize($footer-text-font-size);
        }
      }
    }
  }
}

#murdock_searchwrapper{
  position: relative;
  margin-top: rem-calc(8);
  @include flexBoxes(flex-start, center, row, wrap);
  .scroll-index-search{
    margin-left: 4px;
    height: 20px;
    flex-grow: 1;
  }
  #loader_display{
    width: 14px;
    height: 14px;
    background-size: contain;
    top: 50%;
    right: 6px;
    transform: translateY(-50%);
  }
}