$blogGrey: $light-grey;

// Blog
.blog-page{
  .blog-content{
    margin-top: $content-margin-top;

    aside#colright{
      .tags-wrapper{
        text-align: center;
        margin-top: rem-calc(24);
        margin-bottom: rem-calc(24);
        h5{
          margin-bottom: rem-calc(2);
        }
        li{
          list-style: none;
          a{
            font-family: Book;
            text-transform: uppercase;
            letter-spacing: $base-letter-spacing;
            @include fontSize($small-font-size);
            line-height: 1.17em;
          }
        }
      }
    }
  }
}


@import "../components/blog/author";
@import "../components/blog/blogList";
@import "../components/blog/blogTextImage";