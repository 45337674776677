.searchresult{
  .search{
    margin-bottom: rem-calc(32);
    .search-input{
      margin-bottom: rem-calc(24);
    }
  }
  .search-box{
    width: 80%;
    background-color: $light-grey;
    padding: 4px 8px;
    border: none;
    @include fontSize($text-font-size);
  }

  .result-list{
    border-top: 1px solid $black;
    .search-result-entry{
      border-bottom: 1px solid $black;
      .detail-link{
        font-family: Book;
        text-transform: uppercase;
        letter-spacing: $base-letter-spacing;
        @include fontSize($small-font-size);
        line-height: 1.17em;
        padding-left: rem-calc(12);
        span{
          display: inline-block;
          svg{
            display: block;
            width: $arrow-default-width;
            padding-left: rem-calc(8);
          }
        }
      }
    }
  }
}