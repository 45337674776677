.textAndImage{
  img{
    max-width: 100%;
  }
  table{
    border: none;
    td, tr, p, a, li{
      @include fontSize(18);
    }
    td{
      border: none;
    }
    tr{
      border-bottom: 1px solid $black;
      //&:first-child{
      //  border-top: 1px solid $black;
      //}
    }
    p{
      margin: 0;
    }
  }
}

.simple-text-image{
  width: 100%;
  h2{
    margin-bottom: rem-calc(24);
  }
  img{
    max-width: 100%;
  }
  span.arrow-right{
    display: inline-block;
    svg{
      width: 6px;
      display: block;
    }
  }
  .contententry{
    margin-bottom: 0;
  }
  .image-wrapper{
    padding-bottom: rem-calc(10);
  }
}
.formatted-image{
  position: relative;
  height: 0;
  overflow: hidden;
  max-width: 100%;
  background-size: cover;
  background-position: center;

  &._4-3-format{
    padding-bottom: 75%;
  }
  &._16-9-format{
    padding-bottom: 56.25%;
  }
}

.image-wrapper{
  position: relative;
}

#colright{
  .textAndImage, .simple-text-image{
    &:not(:last-of-type){
      border-bottom: 1px solid $black;
      padding-bottom: rem-calc(22.5);
      margin-bottom: rem-calc(22.5);
    }
    h2, h3{
      margin-bottom: rem-calc(8);
      line-height: 1.2em;
    }
    p:first-of-type{
      margin-top: 0;
    }
    p:last-of-type{
      margin-bottom: 0;
    }
  }
}