.item-list{
  position: relative;
  img{
    max-width: 100%;
  }
  .item-list-entries {
    .item-list-entry {
      border-bottom: 1px solid $black;
      position: relative;
      @media #{$small-only} {
        margin-top: rem-calc(18);
        padding-bottom: rem-calc(10);
        margin-bottom: rem-calc(10);
        h4, .h4-style{
          margin: rem-calc(8px) 0;
          font-weight: bold;
        }
      }
      .item-img-detail {
        width: rem-calc(170);
        height: rem-calc(130);
      }
      .list-item-image{
        width: 100%;
        height: 100%;
        background-position: center;
        background-size: cover;
      }
      .item-list-detail {
        //cursor: pointer;
        .item-date-detail {
          padding-left: rem-calc(32);
        }
        @media #{$small-only} {
          .item-list-detail-title{
            flex-wrap: nowrap;
            align-items: flex-end;
          }
        }
      }
      .item-detail-action{
        width: 50px;
        text-align: center;
        transition: all 1s ease-in-out;
        margin-left: 0;
        padding-right: rem-calc(15);
        svg{
          width: $arrow-default-width;
          top: 64%;
          position: relative;
        }
      }

    }
    .item-short-list-entry{
      position: relative;
      margin-bottom: rem-calc(18);
      .item-date{
        font-family: "Demi";
        @include fontSize(19.2);
        line-height: 1em;
      }
      .item-subline{
        @include fontSize($footer-text-font-size);
        line-height: 1.2em;
      }
    }
  }
}

.item-txt-detail {
  padding-top: rem-calc(32);
  padding-bottom: rem-calc(32);
  width: calc(77% - 220px);
  h6, .h6-style{
    margin-top: 0;
    margin-bottom: rem-calc(16);
    letter-spacing: 0.02rem;
  }
  h4, .h4-style{
    margin: 0;
  }
  h3, .h3-style{
    @include fontSize($title-font-size);
    font-family: Book;
    margin-top: 1.2rem;
  }
  p{
    margin-top: 0;
  }
}

.item-date-detail {
  align-items: center;
  width: 23%;
  .evt-date{
    .evt-day{
      @include fontSize($small-title-font-size);
      line-height: 1em;
      padding-right: 12px;
    }
    .evt-time{
      @include fontSize($text-font-size);
      line-height: 1em;
    }
  }
}

.item-full-detail{
  width: 100%;
  @media #{$medium-down} {
    >.row-layout{
      -webkit-flex-direction: column;
      flex-direction: column;
      .item-date-detail{
        width: 100%;
      }
      .item-txt-detail{
        width: 100%;
        padding-top: 0;
        h6, .h6-style{
          margin-top: rem-calc(6);
        }
        .close-detail {
          padding-right: 0;
          padding-top: rem-calc(32);
          svg{
            width: rem-calc(30);
          }
        }
      }
      .list-item-image{
        padding-bottom: 75%;
      }
    }
  }
  @media #{$medium-up} {
    .item-date-detail{
      width: 16%;
      padding-top: rem-calc(32);
    }
    .item-txt-detail {
      width: 84%;
      .full-detail-text{
        padding-right: rem-calc(32);
      }
      .full-detail-links{
        &, p, a, li {
          font-family: Book;
          letter-spacing: $base-letter-spacing;
          @include fontSize($small-font-size);
          line-height: 1.2em;
        }
      }
      .item-full-detail-img{
        position: relative;
        .list-item-image{
          height: 300px;
          position: absolute;
          bottom: 0;
          left: 0;
        }
      }
      .event-list-exhibition-link{
        padding-top: rem-calc(10);
        padding-bottom: rem-calc(14);
        .exhibition-link{
        }
      }
    }
  }
  .close-detail{
    position: absolute;
    padding: rem-calc(24);
    padding-right: rem-calc(15);
    right: 0;
    top: 0;
    width: 100%;
    text-align: right;
    //cursor: pointer;
    svg{
      width: $arrow-default-width;
    }
  }

  &.fancybox-content{
    width: 100%;
    max-width: $max-width;
    .close-detail{
      display: none;
    }
  }
}

.exhibition-list:not(.teaser-list){
  .item-list-entries .item-list-entry{
    .item-list-detail{
      min-height: 160px;
      .item-img-detail{
        width: 28%;
        height: auto;
      }
      .item-txt-detail{
        width: calc(72% - 50px);
        padding-left: rem-calc(32);
        justify-content: space-between;
      }
      .item-detail-action{
        svg{
          top: 50%;
          transform: translateX(-50%);
          width: 12px;
        }
      }
    }
    &:hover{
      .item-detail-action{
        svg{
          margin-left: rem-calc(12);
        }
      }
    }
  }
}

.teaser-list{
  border: none;
  margin-bottom: $default-margin-bottom;
  .item-list-entries .item-list-entry{
    border: none;
    margin-bottom: $default-margin-bottom;
    .item-list-detail {
      @media #{$medium-up} {
        margin-left: - $default-margin-bottom/2;
        margin-right: - $default-margin-bottom/2;
      }
      .item-img-detail, .item-txt-detail{
        height: auto;
        width: 50%;
        @media #{$medium-down} {
          width: 100%;
          padding: 0;
        }
        @media #{$medium-up} {
          padding-left: $default-margin-bottom/2;
          padding-right: $default-margin-bottom/2;
        }
      }
      .item-txt-detail{
        padding-top: 0;
        -webkit-justify-content: flex-start;
        justify-content: flex-start;
      }
      h2, p{
        margin-top: 0;
        margin-bottom: 0;
      }
      .arrow-right{
        svg{
          width: rem-calc(8);
        }
      }
      .list-item-image{
        padding-bottom: 56.25%;
        height: 0;
      }
      .item-detail-action{
        display: none;
      }
    }
  }
}

.exhibition-list.teaser-list{
  .item-list-entry .item-list-detail {
    h2{
      margin-top: -4px;
      @media #{$medium-down} {
        margin-top: rem-calc(6);
      }
    }
  }
}

.list-filter{
  border-bottom: 1px solid $black;
  border-top: 1px solid $black;
  justify-content: flex-end;
  @media #{$small-only} {
    //display: none;
    &:not(.collection-filter){
      >div:not(.exhibtion-filter){
        height: 0;
        overflow: hidden;
        border: none;
        padding: 0;
        margin: 0;
      }
    }
    .exhibtion-filter{
      width: 100%;
      border-left: 1px solid black;
    }
  }
  .border-right{
    border-right: 1px solid black;
    //padding-left: 12px;
  }

  .filter-item{
    position: relative;
    font-family: Demi;
    text-transform: uppercase;
    letter-spacing: $base-letter-spacing;
    @include fontSize($small-font-size);
    line-height: 1.17em;
    padding: rem-calc(8) rem-calc(12);
    //border:none;
    background:transparent;
    padding-right: rem-calc(48);
    &.filter-title{
      padding-left: 0;
    }
  }
  .filter-arrow{
    position: absolute;
    top: 50%;
    right: 15px;
    transform: translateY(-50%);
    transition: 0.2s ease;
    svg {
      width: $arrow-default-width;
    }
  }
  #reset{
    width: 0px;
    height: 0px;
    padding: 0;
    border: none;
  }
  input.date-picker{
    height: 100%;
    border: none;
    box-sizing: border-box;
    position: absolute;
    width: 1px;
    background: transparent;
    margin-right: -1px;
    opacity: 0;
    top: 0;
    right: 0;
  }
}